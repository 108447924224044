:root {
	/* --dark: #343a40; */
	--dark: #141414;
	--warning: #ffc107;
	--danger: #bd2130;
	--success: #28a745;
	--primary: #007bff;
	--ative-color: var(--danger);
	--bg-color: black;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'PT Sans', sans-serif;
	line-height: 1;
}

html {
	scroll-behavior: smooth;
}

h1 {
	font-size: 26px;
}

.swiper-wrapper {
	/* user-select: none; */
}

.swiper-slide {
	margin-top: auto;
	margin-bottom: auto;
}

.swiper-button-prev {
	left: 0 !important;
	top: 0 !important;
	height: 100% !important;
	width: 40px !important;
	background-color: rgba(14, 14, 14, 0.5) !important;
	margin: 0 !important;
}

.swiper-button-next {
	right: 0 !important;
	top: 0 !important;
	height: 100% !important;
	width: 40px !important;
	background-color: rgba(14, 14, 14, 0.5) !important;
	margin: 0 !important;
}

.oh-hi-mark * {
	margin: revert;
	padding: revert;
	line-height: 1.2;
	white-space: pre-wrap;
}

.oh-hi-mark-teacher-lg * {
	line-height: 1.1;
	font-size: 17px;
}

.oh-hi-mark-teacher * {
	line-height: 1.3;
	font-size: 17px;
}

.oh-hi-mark-concrete-case * {
	line-height: 1.5;
	font-size: 19px;
}

.oh-hi-mark-about * {
	line-height: 1.5;
	font-size: 19px;
}

*:disabled {
	cursor: not-allowed;
	opacity: 0.7;
}

body {
	font-size: 16px;
	background-color: var(--bg-color);
	color: white;
}

table {
	border-collapse: separate;
}

ul {
	list-style: none;
}

a {
	text-decoration: none;
	color: unset;
}

a:visited:not(.btn, .btn-link) {
	color: unset;
}

.text-center {
	text-align: center;
}

.lh-11 {
	line-height: 1.1;
}

.lh-12 {
	line-height: 1.2;
}

.lh-13 {
	line-height: 1.3;
}

.alerts-wrapper {
	opacity: 0.9;
}

.alert-transition {
	opacity: 0;
	transition: opacity 2s;
}

.alert-transition:hover {
	opacity: 0.9;
	transition: none !important;
}

.no-transition {
	transition: none !important;
}

.flex {
	display: flex;
}

.flex-basis-auto {
	flex-basis: auto;
	flex-grow: 1;
}

.content-center {
	justify-content: center;
}

.items-center {
	align-items: center;
}

.space-evenly {
	justify-content: space-evenly;
}

.space-between {
	justify-content: space-between;
}

.space-around {
	justify-content: space-around;
}

.wrap {
	flex-wrap: wrap;
}

.flex-column {
	flex-direction: column;
}

.flex-row {
	flex-direction: row;
}

.flex-between {
	justify-content: space-between;
}

.align-items-center {
	align-items: center;
}

.cursor-pointer:hover {
	cursor: pointer;
}

.gap-xs {
	gap: 5px;
}

.gap-sm {
	gap: 15px;
}

.gap-md {
	gap: 30px;
}

.gap-lg {
	gap: 50px;
}

.gap-1p {
	gap: 1%;
}

.font-lg {
	font-size: 3.5rem;
}

.font-md {
	font-size: 2.5rem;
}

.m-auto {
	margin: auto;
}

.mb-15 {
	margin-bottom: 15px;
}

.mb-10 {
	margin-bottom: 10px;
}

.ml-10 {
	margin-left: 10px;
}

.mr-10 {
	margin-right: 10px;
}

.mt-10 {
	margin-top: 10px;
}

.padding-0 {
	padding: 0;
}

.hidden {
	display: none;
}

.container {
	max-width: 1140px;
}

.table {
	background-color: #d6d8db;
	color: black;
	border: 1px solid white;
}

.table tbody tr:hover {
	color: gray;
	opacity: 1;
}

.table thead tr {
	background-color: #495057;
	color: white;
}

.table tbody tr:nth-of-type(2n + 1) {
	background-color: #9fa1a4;
}

.table th,
.table td {
	vertical-align: middle;
	line-height: 1.5;
	padding: 0.35rem;
}

.table tbody td a {
	display: inline-block;
	width: 100%;
}

.table th {
	text-align: left;
}

.table td {
	border-top: 1px solid #482d2d;
}

.border-1px-white {
	border: 1px solid white;
	margin: 1px;
}

.border-2px-hover:hover {
	border-width: 2px;
	margin: 0px;
}

.btn {
	font-size: 16px;
	color: white;
	padding: 5px 10px;
	border-radius: 5px;
	border: 1px solid transparent;
	transition: opacity 0.15s ease-in-out;
}

.btn:hover {
	opacity: 0.8;
	cursor: pointer;
}

.btn:disabled {
	cursor: not-allowed;
}

.btn-warning {
	color: #212529;
	background-color: #ffc107;
	border-color: #ffc107;
}

.btn-danger {
	color: #fff;
	background-color: #dc3545;
	border-color: #dc3545;
}

.btn-success {
	color: #fff;
	background-color: var(--success);
	border-color: var(--success);
}

.btn-secondary {
	color: #fff;
	background-color: #6c757d;
	border-color: #6c757d;
}

.btn-link {
	font-weight: 400;
	color: #007bff;
	background-color: transparent;
}

.btn-group-toggle .btn:not(:first-child):not(:last-child) {
	border-radius: 0;
}

.btn-group-toggle .btn:first-child {
	margin-right: 0;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.btn-group-toggle .btn:last-child {
	margin-left: 0;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.btn-group-toggle .active {
	background-color: #545b62;
	border-color: #4e555b;
}

.btn-primary {
	color: #fff;
	background-color: #007bff;
	border-color: #007bff;
}

.btn-info {
	color: #fff;
	background-color: #17a2b8;
	border-color: #17a2b8;
}

.btn-bg {
	padding: 20px;
	font-size: 20px;
	font-weight: bold;
}

.hover-select:hover {
	cursor: pointer;
	opacity: 0.7;
}

.text-danger {
	color: var(--danger);
}

.text-success {
	color: var(--success);
}

.text-primary {
	color: var(--primary);
}

.table-input {
	width: 100%;
	padding: 0.375rem 0.75rem;
	font-size: 1rem;
	line-height: 1.5;
	color: white;
	background-color: var(--bg-color);
	background-clip: padding-box;
	border: none;
	border-radius: 0.25rem;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	box-sizing: border-box;
}

.table-input:focus,
.table-input:hover {
	color: black;
	background-color: white;
}

.alert {
	position: relative;
	padding: 0.75rem 1.25rem;
	margin-bottom: 1rem;
	border: 1px solid transparent;
	border-radius: 0.25rem;
}

.alert-danger {
	color: #721c24;
	background-color: #f8d7da;
	border-color: #f5c6cb;
}

.alert-warning {
	color: #856404;
	background-color: #fff3cd;
	border-color: #ffeeba;
}

.alert-success {
	color: #155724;
	background-color: #d4edda;
	border-color: #c3e6cb;
}

.w-100 {
	width: 100%;
}

.w-49 {
	width: 49%;
}

.w-12ch {
	width: 12ch;
}

.m-center {
	margin-left: auto;
	margin-right: auto;
}

.hover-children-select > *:hover {
	opacity: 0.8;
	cursor: pointer;
	/* color: var(--ative-color); */
}

.hover-select:hover {
	opacity: 0.8;
	cursor: pointer;
	/* color: var(--ative-color); */
}

.mb-children-sm > * {
	margin-bottom: 15px;
}

.mb-xs {
	margin-bottom: 5px;
}

.mb-sm {
	margin-bottom: 15px;
}

.evaed-logo {
	height: 65px;
	width: 100%;
	max-width: 230px !important;
}

.evaed-logo-footer {
	width: auto !important;
}

.concrete-case-video-title {
	display: none;
}

.concrete-case-video-wrapper:not(.fullscreen):hover .concrete-case-video-title {
	display: block;
}

@keyframes spin {
	100% {
		transform: rotate(360deg);
	}
}

.spinner {
	animation: spin 2s linear infinite;
}

.pagination {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	gap: 10px;
}

.pagination a {
	padding: 5px;
}

.pagination .active {
	color: var(--ative-color);
}

.pagination .disabled {
	opacity: 0.7;
}

.pagination .disabled a {
	cursor: not-allowed;
}

.form-group label {
	display: inline-block;
	margin-bottom: 0.5rem;
	font-size: 1.2rem;
}

.form-group-big-input {
	position: relative;
}

.form-group-big-input label {
	position: absolute;
	color: black;
	top: 5px;
	left: 15px;
	font-size: 18px;
}

.form-group-big-input .form-control {
	padding: 35px 15px 5px 15px;
	background-color: rgb(232, 240, 254);
	color: black;
}

textarea.form-control {
	resize: vertical;
	min-height: 100px;
}

textarea.form-control-sm {
	min-height: 50px;
}

.form-control {
	display: block;
	width: 100%;
	padding: 0.375rem 0.75rem;
	font-size: 1rem;
	line-height: 1.5;
	color: #495057;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control-compact {
	padding: 0.15rem 0.45rem;
}

.input-group .form-control {
	display: inline-block;
	padding-right: 30px;
}

.input-group .input-addon {
	display: inline-block;
	margin-left: -25px;
	color: black;
}

.text-muted {
	color: #6c757d !important;
}

.form-text {
	display: block;
	margin-top: 0.25rem;
}

.disabled {
	cursor: not-allowed;
	opacity: 0.7;
}

/* @media only screen and (max-width: 35ch) {
	.login-form {
		width: 100%;
	}
} */

.navbar {
	padding-left: 10px;
	padding-top: 3px;
	display: flex;
	height: 70px;
	justify-content: space-between;
	align-items: center;
	position: sticky;
	top: 0;
	background-color: var(--bg-color);
	z-index: 100;
}

.navbar__items {
	display: flex;
	align-items: center;
}

/* .navbar__items li {
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
}

.navbar__items li:hover {
	cursor: pointer;
	opacity: 0.7;
} */

.visible-mobile {
	display: none;
}

.navbar-mobile {
	width: 100vw;
	position: fixed;
	bottom: 0;
	background-color: var(--bg-color);
	padding-top: 15px;
	padding-bottom: 5px;
	display: none;
	z-index: 999999;
	font-size: 13px;
}

.navbar-mobile__items {
	display: flex;
	justify-content: space-evenly;
	flex-direction: row;
}

.navbar-mobile__items li {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.main {
	display: flex;
	flex-direction: column;
	margin-bottom: 40px;
}

.main,
.main-admin-panel {
	padding: 0.5rem 0.5rem;
}

.video {
	display: block;
	background-color: #1a1a1a;
	height: 100%;
	width: 100%;
}

.video__thumbnail {
	max-width: 100%;
	width: 100%;
	height: auto;
	cursor: pointer;
}

.video__pending-comments {
	background-color: red;
	font-weight: bold;
	padding: 7;
	user-select: none;
	position: absolute;
	top: 3px;
	right: 3px;
}

.footer {
	margin-top: 15px;
	padding-bottom: 15px;
	z-index: 2000;
	background-color: #141414;
}

input.search::before {
	content: '\f002';
}

.btn-login {
	background-color: #dc3545;
	border-color: #dc3545;
}

.btn-login:hover {
	background-color: #c82333;
	border-color: var(--ative-color);
	cursor: pointer;
}

.admin-panel-list li {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	font-size: 1.75rem;
	text-align: center;
	max-width: 19ch;
}

.admin-panel-list li.active {
	color: var(--ative-color);
}

.admin-panel-list li i {
	font-size: 3rem;
}

.admin-panel-list-sm {
	flex-direction: column;
	justify-content: start;
	gap: 2rem;
}

.admin-panel-list-sm li h3 {
	display: none;
}

.navbar__items .menu-hover {
	display: none;
}

@media only screen and (max-width: 385px) {
	.evaed-logo {
		width: calc(100% - 28px);
		height: auto;
	}

	.evaed-logo-min {
		width: 100%;
		height: 65px;
	}
}

@media only screen and (max-width: 1200px) {
	.navbar__items {
		position: absolute;
		flex-direction: column;
		left: 290px;
		top: 28px;
		background-color: black;
		justify-content: flex-start;
		padding: 0 15px 15px 15px;
	}
	.navbar__items .menu-hover {
		display: flex;
		align-self: flex-start;
	}
	.menu-item {
		display: none;
	}
	.navbar__items:hover .menu-item,
	.navbar__items:focus .menu-item {
		display: flex;
	}
	.navbar__items:hover .menu-hover,
	.navbar__items:focus .menu-hover {
		margin: 0;
	}
	body {
		padding-bottom: 60px;
	}
}

@media only screen and (max-width: 850px) {
	.navbar-mobile {
		display: flex;
	}

	.visible-mobile {
		display: unset;
	}

	.navbar__items {
		display: none;
	}

	.swiper-button-prev {
		display: none !important;
	}

	.swiper-button-next {
		display: none !important;
	}

	/* .navbar {
		display: none;
	} */
}

.double-input-inline {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.double-input-inline div {
	width: 49.5%;
}

@media (max-width: 600px) {
	.double-input-inline div {
		width: 100%;
	}
	.double-input-inline div:nth-child(2n) {
		padding-top: 1rem;
	}
}

.overlay-modal {
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	justify-content: center;
	align-items: center;
	z-index: 200;
}

.content-modal {
	margin: auto;
	width: 50%;
	height: fit-content;
	min-height: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	justify-content: center;
	background-color: #141414;
	z-index: 201;
	outline: none;
}

.modal-close-button {
	width: 100%;
	display: flex;
	justify-content: flex-end;
}

.modal-title-holder {
	margin-bottom: 50px;
}

.modal-title-holder div {
	text-align: center;
	margin: 10px;
}

.icons-holder {
	height: fit-content;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.icons-holder button {
	background: none;
	border: none;
	padding: 20px;
	cursor: pointer;
	outline: none;
}

.icons-holder i {
	color: white;
}

.contact-form {
	width: 100%;
	border: 1px solid white;
}

.phone-contact {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	padding: 50px;
	text-align: center;
}

.phone-contact-title {
	font-size: 30px;
}

.phone-contact-number {
	text-align: center;
	background-color: red;
	padding: 10px 50px;
	margin: 20px;
	font-size: 30px;
}

.whatsapp-contact {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	padding: 50px;
	text-align: center;
}

.whatsapp-contact-title {
	font-size: 25px;
	text-align: center;
}
.whatsapp-contact-number {
	text-align: center;
	background-color: green;
	padding: 10px;
	margin: 20px;
	font-size: 30px;
}

@media only screen and (max-width: 600px) {
	.overlay-modal {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.content-modal {
		align-items: center;
		width: 95%;
		height: fit-content;
		max-height: 99%;
		align-items: center;
	}
	.icons-holder {
		height: fit-content;
		display: flex;
		justify-content: space-around;
		align-items: center;
	}
	.modal-title-holder div {
		margin: 0;
	}
}

.live-progress-bar {
	transition: ease-out 1s;
}

.floating-error {
	position: absolute;
	top: 50%;
	left: 50%;
	font-size: 50;
	color: darkred;
	opacity: 0.8;
	transform: translateX(-50%) translateY(-50%);
}
